import { Span } from '@playbooks/interface/html';
import { BorderLink } from '@playbooks/interface/links';
import { Skeleton } from 'molecules/skeletons';

const DemoBtn = ({ repo = {}, loading }) => {
	// Render
	return (
		<BorderLink
			size='lg'
			nextIcon='arrow-up-right'
			href={repo.demo?.computedUrl}
			disabled={loading}
			target='_blank'
			className='w-full'>
			{loading ? <Skeleton className='w-20' /> : <Span>View Demo</Span>}
		</BorderLink>
	);
};

export { DemoBtn };
