import { useState } from 'react';

import { BorderBtn } from '@playbooks/interface/buttons';
import { Span } from '@playbooks/interface/html';
import { client, formatOptions } from 'api';
import { useStore, useToast } from 'contexts';
import { CodeSandboxBodyDecorator, CodeSandboxFilesDecorator } from 'decorators';
import { Skeleton } from 'molecules/skeletons';
import { log } from 'utils';

const CodesandboxBtn = ({ repo = {}, loading }) => {
	const [taskRunning, setTaskRunning] = useState(false);
	const store = useStore();
	const toast = useToast();

	// Methods
	const onCode = async () => {
		try {
			setTaskRunning(true);
			const response = await store.query({ url: `/repos/${repo.uuid}/tree` });
			const url = `https://codesandbox.io/api/v1/sandboxes/define?json=1`;
			const files = CodeSandboxFilesDecorator(response.data);
			const body = CodeSandboxBodyDecorator(files);
			const options = formatOptions('POST', { accept: 'application/json' }, body);
			log('codesandbox: ', { url, options, files });
			const data = await client(url, options);
			log('codesandbox: ', { data });
			window.open(`https://codesandbox.io/s/${data.sandbox_id}`, '_blank');
		} catch (e) {
			toast.showError(e || { status: 422 });
		} finally {
			setTaskRunning(false);
		}
	};

	// Render
	return (
		<BorderBtn
			size='lg'
			nextIcon='arrow-up-right'
			disabled={loading}
			taskRunning={taskRunning}
			onClick={onCode}
			className='w-full'>
			{loading ? <Skeleton className='w-20' /> : <Span>View Codesandbox</Span>}
		</BorderBtn>
	);
};

export { CodesandboxBtn };

// Docs
// https://codesandbox.io/docs/learn/sandboxes/cli-api
