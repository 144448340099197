import { useState } from 'react';

import { BorderBtn } from '@playbooks/interface/buttons';
import { Span } from '@playbooks/interface/html';
import stackblitz from '@stackblitz/sdk';
import { useStore, useToast } from 'contexts';
import {
	StackBlitzDependencyDecorator,
	StackBlitzFilesDecorator,
	StackBlitzOpenDecorator,
	StackBlitzProjectDecorator,
} from 'decorators';
import { Skeleton } from 'molecules/skeletons';
import { log } from 'utils';

const StackblitzBtn = ({ repo = {}, loading }) => {
	const [taskRunning, setTaskRunning] = useState(false);
	const store = useStore();
	const toast = useToast();

	// Methods
	const onBlitz = async () => {
		try {
			setTaskRunning(true);
			const response = await store.query({ url: `/repos/${repo.uuid}/tree` });
			const dependencies = StackBlitzDependencyDecorator(response.data);
			const files = StackBlitzFilesDecorator(response.data);
			const project = { ...StackBlitzProjectDecorator(repo), template: 'node', dependencies, files };
			const projectSettings = StackBlitzOpenDecorator();
			log('stackblitz: ', { project, projectSettings });
			stackblitz.openProject(project, projectSettings);
		} catch (e) {
			toast.showError(e);
		} finally {
			setTaskRunning(false);
		}
	};

	// Render
	return (
		<BorderBtn
			size='lg'
			nextIcon='arrow-up-right'
			disabled={loading}
			taskRunning={taskRunning}
			onClick={onBlitz}
			className='w-full'>
			{loading ? <Skeleton className='w-20' /> : <Span>View Stackblitz</Span>}
		</BorderBtn>
	);
};

export { StackblitzBtn };

// Docs
// https://developer.stackblitz.com/platform/api/javascript-sdk#openproject
// https://developer.stackblitz.com/platform/api/javascript-sdk-options#project
// https://developer.stackblitz.com/platform/api/javascript-sdk-options#projectfiles
